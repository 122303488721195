import React from 'react'

function Maintenance() {
    return (
        <div className="changes"> 
              <div className="changes-text">
            <img src="/app-icons/small/maintenance.svg"></img>
            <h2>Oops, we're under maintenance...</h2>
        </div>
        </div>
    )
}

export default Maintenance
